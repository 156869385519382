import {
  VpnServers,
  PowerSchedules,
  PowerTypes,
  RouterStatuses,
} from "@/components/constants.js"

const battery = [
  { name: "All", value: "" },
  { name: "Has Battery", value: "true" },
  { name: "No Battery", value: "false" },
]

export default (self) => {
  return {
    routerType: {
      component: "SelectSearchFilter",
      attributes: {
        items: [{ value: "", name: "All" }, ...self.routerTypes],
        value: "",
      },
    },
    vpnServer: {
      component: "SelectSearchFilter",
      attributes: {
        items: [{ value: "", name: "All" }, ...VpnServers],
        value: "",
      },
    },
    serialNumber: {
      component: "TextFieldSearchFilter",
    },
    cameras: {
      component: "TextFieldSearchFilter",
    },
    projects: {
      component: "TextFieldSearchFilter",
    },
    billPayers: {
      component: "SelectSearchFilter",
      attributes: {
        items: [{ value: "", name: "All" }, ...self.billPayers],
        value: "",
      },
    },
    sims: {
      component: "TextFieldSearchFilter",
    },
    lastSms: {
      component: "TextFieldSearchFilter",
    },
    lastSmsAt: {
      component: "DatePickerSearchFilter",
      attributes: {
        textFieldAttrs: {
          placeholder: "Deliver At",
        },
        convertToIso: false,
      },
    },
    lastSeen: {
      name: "hasBattery",
      component: "SelectSearchFilter",
      attributes: {
        items: battery,
      },
    },
    status: {
      component: "SelectSearchFilter",
      attributes: {
        items: [{ value: "", name: "All" }, ...RouterStatuses],
        value: "",
      },
    },
    powerType: {
      component: "SelectSearchFilter",
      attributes: {
        items: [{ value: "", name: "All" }, ...PowerTypes],
        value: "",
      },
    },
    powerSchedule: {
      component: "SelectSearchFilter",
      attributes: {
        items: [{ value: "", name: "All" }, ...PowerSchedules],
        value: "",
      },
    },
    routerHttpPort: {
      component: "TextFieldSearchFilter",
    },
  }
}
